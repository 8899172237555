<template>
  <h1>{{labels.pageSubheading}}</h1>
  <h6>{{labels.passwordRules}}</h6>
  <form class="login-form">
      <mcw-button
        @click.prevent="visibility = !visibility"
        :trailingIcon="visibility ? 'visibility' : 'visibility_off'">
        {{visibility ? labels.visibility_on : labels.visibility_off}}
      </mcw-button>
    <div class="field-group">
      <mcw-textfield
        v-model.trim="password_1"
        :label="labels.firstInput"
        :helptext="labels.noticeValidation"
        helptext-validation
        @blur="validatePassword(password_1)"
        @focus="resetError"
        :type="visibility ? 'password' : 'text'"
        :disabled="success"
        required
        outline
        secondary
        :valid="isValid"
      ></mcw-textfield>
    </div>
    <div class="field-group">
      <mcw-textfield
        v-model.trim="password_2"
        :label="labels.secondInput"
        :helptext="labels.noticeValidation"
        helptext-validation
        @focus="resetError"
        :type="visibility ? 'password' : 'text'"
        required
        :disabled="success"
        outline
        secondary
      ></mcw-textfield>
    </div>
    <div class="buttons-container">
      <mcw-button
        @click.prevent="sendNewPassword"
        :disabled="success"
        raised>
        {{labels.send}}
      </mcw-button>
    </div>
    <router-link
      v-if="success"
      class="login-link"
      to="/login">
      {{labels.loginLink}}
    </router-link>

  </form>
  <mcw-snackbar
  v-model="isCompared"
  :message="labels.noticeNotCompared"
  :dismissAction="false"
></mcw-snackbar>
  <mcw-snackbar
  v-model="isFilled"
  :message="labels.noticeEmptyFields"
  :dismissAction="false"
></mcw-snackbar>
  <mcw-snackbar
  v-model="success"
  :message="labels.successMessage"
  :dismissAction="false"
></mcw-snackbar>
  <mcw-snackbar
  v-model="error"
  :message="labels.errorMessage"
  :dismissAction="false"
></mcw-snackbar>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      text: '',
      labels: {
        firstInput: 'Введите пароль',
        secondInput: 'Повторите ввод пароля',
        noticeValidation: `Пароль должен состоять не менее чем из 6 символов,
        содержать строчные и заглавные латинские буквы и хотя бы одну цифру`,
        noticeNotCompared: 'Пароли не совпадают',
        noticeEmptyFields: 'Заполните оба поля',
        pageHeading: 'Задайте пароль',
        pageSubheading: 'Для входа в MedSafe нужно задать и запомнить пароль',
        passwordRules: `Пароль должен состоять не менее чем из 6 символов,
        содержать строчные и заглавные латинские буквы и хотя бы одну цифру`,
        visibility_on: 'Показать вводимые символы',
        visibility_off: 'Скрыть вводимые символы',
        send: 'отправить',
        successMessage: 'Пароль успешно задан!',
        errorMessage: 'Не удалось задать пароль, обратитесь в техподдержку',
        loginLink: 'Перейти на страницу входа',
      },
      reg: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}/g,
      password_1: '',
      password_2: '',
      isValid: true,
      isFilled: false,
      isCompared: false,
      visibility: true,
      success: false,
      error: false,
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.id_login;
    },
  },
  methods: {
    validatePassword(passwordString) {
      this.isValid = this.reg.test(passwordString);
      return this.reg.test(passwordString);
    },
    resetError() {
      this.isValid = true;
    },
    comparePasswords() {
      if (this.password_1 === this.password_2 && this.password_2 !== '') {
        return true;
      }
      return false;
    },
    async sendNewPassword() {
      // if (!this.validatePassword(this.password_1)) {
      //   return;
      // }
      if (this.password_1 === '' || this.password_2 === '') {
        this.isFilled = true;
        return;
      }
      if (!this.comparePasswords()) {
        this.notifyPasswordsNotCompared();
        return;
      }
      const result = await this.$store.dispatch('auth/setPassword', { password: this.password_1, userId: this.userId });
      if (result && result.err === 0) {
        this.success = true;
      } else {
        this.error = true;
      }
    },
    notifyPasswordsNotCompared() {
      this.isCompared = true;
    },
  },
};
</script>

<style lang="scss">

</style>
